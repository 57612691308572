.payment-page {
  @media (min-width: 1680px) {
    width: 80%;
  }
}

.selected-payment {
  height: 37.9px;
  padding: 11px;
  border: 1px solid #d9d0d0;
  border-radius: 10px;
  background: #f6f6f6;
  font-size: 12px;
  color: #c8c8c8;
  min-width: 145px;
}

.total-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 1rem;
  margin-top: 5rem;
}

.payment-modal {
  width: 60%;
  max-height: 90%;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #00bf63;
    border-radius: 8px;
  }
  @media (min-width: 1000px) and (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 98%;
  }
}

.payment-confirm-check {
  width: 100%;
  display: flex;
  box-shadow: 0px 10px 15px 0px #0000000d, 0px 4px 8px 0px #0000000d;
  position: relative;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 8px;
    font-size: 12px;
    font-family: "Monsterrat 500" !important;
    text-align: center;
    width: 20%;
    @media (max-width: 1000px) {
      width: 100%;
    }
    img {
      max-height: 60px;
      width: 80px;
      margin: 0 auto 0.5rem auto;
      object-fit: contain;
    }
  }
  .center {
    width: 60%;
    border-left: 1px solid #a9a4a4;
    border-right: 1px solid #a9a4a4;
    @media (max-width: 1000px) {
      width: 100%;
      border: none;
      padding: 1rem 0 1rem 10px;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid #8b8b8b;
      padding: 12px;
      .item-left {
        font-size: 13px;
      }
      .item-right {
        font-family: "Monsterrat 500" !important;
        font-size: 13px;
      }
    }
    .center-footer {
      display: flex;
      margin-top: 6px;
      border-top: 0.5px solid #a9a4a4;
      > div {
        flex: 1;
        border-right: 0.5px solid #a9a4a4;
        .footer-label {
          font-size: 11px;
          padding: 8px 4px;
          text-align: center;
        }
      }
      .footer-text {
        padding: 14px 10px;
        font-size: 11px;
        text-align: center;
        border-top: 0.5px solid #a9a4a4;
        font-family: "Monsterrat 500" !important;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding: 0 12px;
    @media (max-width: 1000px) {
      width: 100%;
      padding-bottom: 1.2rem;
    }
    .qr {
      width: 100px;
      height: 100px;
    }
  }
}

.payment-loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.show-complex {
  height: auto;
  overflow: initial;
}
.unshow-complex {
  height: 0;
  overflow: hidden;
  display: none;
}

.last-transaction-button {
  color: #ff2020 !important;
}
