.title {
  color: #000;
  font-size: 16px;
  line-height: 19px;
  margin-top: 1.8rem;
  font-family: "Monsterrat 600";
  font-weight: 600;
}

.form-control {
  display: flex;
  align-items: center;
  position: relative;
}

.base-input {
  background: #fff;
  border-radius: 8px 0 0 8px;
  padding: 11px;
  height: 38px;
  width: 136px;
  color: #3e3e3e;
  font-size: 13px;
  border: 0.8px solid transparent;
  transition: border 500ms;
  &::placeholder {
    color: #858181;
    font-size: 12px;
  }
  &:focus,
  &:active {
    border-color: #00adef !important;
  }
  &.notActive {
    border-color: red !important;
    &:focus,
    &:active {
      border-color: red !important;
    }
  }
}

.base-icon {
  height: 38px;
  width: 40px;
  border: 0.5px solid #7e7a7a;
  background: #f6f6f6;
  border-radius: 0 8px 8px 0;
  transition: background 500ms;
  cursor: pointer;
  &:hover {
    background: #e2e2e2;
  }
  svg {
    margin: auto;
    font-family: "Monsterrat 300";
  }
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;
  position: relative;
  label {
    display: block;
    font-size: 13px;
    color: #000;
    .label-price {
      color: #8b8b8b !important;
      font-size: 10px !important;
    }
  }
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
.css-pwxzbm {
  max-height: 500px !important;
  .css-i4bv87-MuiSvgIcon-root {
    fill: #1ed17b;
  }
  .item-text {
    padding-left: 10px;
    text-wrap: wrap !important;
    span,
    .css-yb0lig {
      font-size: 12px !important;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Monsterrat 400" !important;
    }
  }
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
  .css-1km1ehz {
    box-shadow: 0px 4px 8px 0px #00000008 !important;
  }
}
.css-10hburv-MuiTypography-root {
  font-family: "Monsterrat 400" !important;
}
.input {
  border: 1px solid #d9d0d0;
  background: #f6f6f6;
  font-size: 13px;
  color: #000;
  padding: 11px;
  border-radius: 10px;
  border: 1px solid #d9d0d0;
  transition: border 500ms;
  &::placeholder {
    font-size: 12px;
    color: #c8c8c8;
  }
  &.input-error {
    border: 1px solid #f14b4b;
  }
  &:focus,
  &:active {
    border-color: #00adef;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  padding: 8px 12px;
  transition: background 500ms;
  cursor: pointer;
  &.button-success {
    background: #1ed17b;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    font-family: "Monsterrat 500";
    &:hover {
      background: #11a17b;
    }
  }
  &.button-primary {
    background: #00adef;
    color: #fff;
    box-shadow: 0px 10px 15px 0px #00adef0d;
    border-radius: 10px;
    font-size: 13px;
    font-family: "Monsterrat 500";
    transition: background 500ms, box-shadow 500mx;
    &:hover {
      background: #000def;
      box-shadow: none;
    }
  }
  &.button-slate {
    color: #000;
    background: #efefef;
    border: 1px solid #d9d0d0;
    font-size: 13px;
    border-radius: 10px;
    transition: background 500ms;
    &:hover {
      background: #d9d0d0;
    }
  }
}

.select {
  border: 1px solid #d9d0d0 !important;
  background: #f6f6f6 !important;
  font-size: 13px !important;
  color: #000 !important;
  // padding: 11px;
  border-radius: 10px !important;
  font-family: "Monsterrat 400" !important;
  transition: border 500ms;
  fieldset {
    border: none !important;
  }
  &.error {
    border: 1px solid #ff2020 !important;
  }
  &.select-green {
    .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
    .css-182didf.css-182didf.css-182didf {
      color: #00bf63;
      font-family: "Monsterrat 500" !important;
    }
  }
  &.chip {
    min-height: 37.9px !important;
    .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
    .css-182didf {
      padding: 2px !important;
    }
    .chip-item {
      > div {
        border-radius: 10px !important;
        background: rgba(0, 173, 239, 0.1) !important;
        span {
          color: #616362 !important;
          font-size: 13px !important;
        }
      }
    }
  }
}
.personal-account-item {
  font-size: 13px !important;
  font-family: "Monsterrat 600" !important;
  box-shadow: none !important;
}

.table {
  min-width: max-content;
  width: 100%;
  background: #fff;
  border-radius: 15px 15px 0 0;
  tbody {
    tr {
      &:nth-child(even) {
        background: #eeeded;
      }
    }
  }
  .strong-tr {
    td {
      font-family: "Monsterrat 600";
    }
  }
  th {
    padding: 1rem 8px;
    max-width: 225px;
    min-width: 88px;
    font-size: 13px;
    text-align: left;
  }
  td {
    padding: 1rem 8px;
    font-size: 13px;
    &.bg-danger {
      background: #f42020 !important;
      color: #fff !important;
      font-family: "Monsterrat 500" !important;
      font-size: 15px !important;
    }
    &.bg-warning {
      background: #ffb800 !important;
      color: #fff !important;
      font-family: "Monsterrat 500" !important;
      font-size: 15px !important;
    }
  }
  &.table-bordered {
    thead {
      tr {
        border-bottom: 1.5px solid #8b8b8b;
      }
    }
    tr {
      th {
        &:not(:last-child) {
          border-right: 1px solid #8b8b8b;
        }
      }
      td {
        &:not(:last-child) {
          border-right: 1px solid #c8c8c8;
        }
      }
    }
  }
  &.table-custom {
    thead {
      tr {
        border-bottom: 1.5px solid #8b8b8b;
      }
    }
    tr {
      th {
        position: relative;
        padding: 1.25rem 12px;
        &:not(:last-child)::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          height: 30px;
          width: 1px;
          background: #8b8b8b;
        }
      }
    }
  }
}

.main-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
  margin-top: auto;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 605px;
  max-height: 90%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  overflow-y: auto;
  &.transaction-modal {
    width: 90%;
    height: 90%;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #00bf63;
    border-radius: 8px;
  }
  @media (max-width: 767px) {
    width: 98%;
  }
  .modal-body {
    padding: 1.2rem;
    position: relative;
    overflow-y: auto;
    @media (max-width: 767px) {
      padding: 1.2rem 12px;
    }
    .modal-loading {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
    }
  }
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #00adef;
  border-radius: 10px 10px 0 0;
  padding: 16px 20px;
  .header-title {
    font-family: "Monsterrat 500";
    color: #fff;
  }
  button {
    background: transparent;
    transition: background 500ms;
    &:hover {
      background: rgb(255, 255, 255, 0.3);
    }
  }
}
.modal-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  gap: 12px;
  .info-button {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 13px;
    border: 1px solid #00bf63;
    border-radius: 5px;
    padding: 8px 16px;
    transition: background 500ms, color 500ms;
    &:hover {
      background: #00bf63;
      color: #fff;
    }
  }
}

.modal-block {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 1rem;
  label {
    font-size: 13px;
  }
}

.modal-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 12px 0;
  border-top: 0.5px solid #8b8b8b;
  font-size: 13px;
  strong {
    font-family: "Monsterrat 600" !important;
    font-size: 13px;
    text-align: right;
  }
}

.stepper-indicators {
  display: grid;
  margin-bottom: 1rem;
  .indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0.8rem 0;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background: #c8c8c8;
      transform: translateY(-50%);
    }
    &.active {
      &::before {
        background: #1ed17b;
      }
      .indicator-count {
        background: #1ed17b;
      }
      svg {
        display: block;
      }
      .indicator-title {
        color: #3e3e3e;
      }
    }
    &.current {
      &::before {
        background: #00adef;
      }
      .indicator-count {
        background: #00adef;
      }
    }
    svg {
      display: none;
    }
    .indicator-count {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #d9d9d9;
      color: #fff;
      font-size: 32px;
      z-index: 1;
      @media (max-width: 767px) {
        width: 40px;
        height: 40px;
        font-size: 24px;
      }
    }
  }
  .indicator-title {
    position: absolute;
    top: 100%;
    color: #858181;
    font-size: 14px;
    @media (max-width: 767px) {
      font-size: 12px;
      text-align: center;
    }
  }
}

.select-arrow {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url("../images/selectArrow.png") no-repeat right white;
  background-size: contain;
  display: inline-block;
  position: relative;
  -webkit-appearance: none;
  padding-right: 3rem;
}

.MuiTooltip-tooltip {
  font-size: 16px !important;
}
.MuiCircularProgress-circle {
  color: #00adef !important;
}
.courier-select .MuiInputBase-input {
  font-family: "Monsterrat 400" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.video-player {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background: #00000078;
  }
}

.responsive-block {
  @media (min-width: 1400px) and (max-width: 1600px) {
    width: 200px;
  }
}
