@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Monsterrat 300";
  src: url("./assets/fonts/Montserrat-Light.ttf");
  font-weight: 300;
  font-weight: normal;
}
@font-face {
  font-family: "Monsterrat 400";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
  font-weight: 400;
  font-weight: normal;
}
@font-face {
  font-family: "Monsterrat 500";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
  font-weight: 500;
  font-weight: normal;
}
@font-face {
  font-family: "Monsterrat 600";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
  font-weight: 600;
  font-weight: normal;
}
@font-face {
  font-family: "Monsterrat 700";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
  font-weight: 700;
  font-weight: normal;
}

.font-300 {
  font-family: "Monsterrat 300";
  font-weight: 300;
}
.font-400 {
  font-family: "Monsterrat 400" !important;
  font-weight: 400 !important;
}
.font-500 {
  font-family: "Monsterrat 500";
  font-weight: 500;
}
.font-600 {
  font-family: "Monsterrat 600";
  font-weight: 600;
}
.font-700 {
  font-family: "Monsterrat 700";
  font-weight: 700;
}

.main-gray {
  color: #3e3e3e;
}

.color-black {
  color: #000;
}

.color-blue {
  color: #00adef;
}

.color-gray {
  color: #757575;
}

.color-green {
  color: #00bf63;
}

.input-gray {
  color: #f6f6f6;
}

.color-white {
  color: #fff;
}

.body-color {
  color: #f5f5f5;
}

.button-color {
  background: #d9d9d9 !important;
}

* {
  font-family: "Monsterrat 400";
  font-weight: 400;
  color: #3e3e3e;
  line-height: 1.1;
  outline: none;
  scroll-behavior: smooth;
}

body {
  background: #f5f5f5;
  overflow-x: hidden;
  width: 100vw;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 200vh;
  padding-left: 315px;
}

.content {
  padding: 0 1.8rem 1.8rem 1.8rem;
}

.wrapper {
  background: #fff;
  margin-top: 1.8rem;
  border-radius: 15px;
  padding: 1.8rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.error {
  border-color: red !important;
}

.error-text{
  color: red !important;
}

@media (max-width: 1280px) {
  .page {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .content {
    padding: 0 15px 15px 15px;
  }
  .wrapper {
    margin-top: 1rem;
    padding: 1.8rem 15px;
  }
}
